// Use `@extend %button-defaults` to load the default styles below for the button
%button-defaults {
  display: inline-block;
  border-radius: 4px;
  padding: 12px 16px;
  border: none;
  transition: .2s ease-in-out;
  font-size: 16px;
}


// The `btn` class is the default button. You can add more button styles at 
// the end or overwrite its style directly.

/**
 * Solid button
 */
.btn-theme {
  background-color: color(primary);
  color: #fff;
  box-shadow: 2px 3px 8px rgba(#000, 25%);
  @extend %button-defaults;

  &:hover {
    background-color: lighten(color(primary), 5%);
    box-shadow: 2px 8px 16px rgba(#000, 30%);
  }

  &:active {
      background-color: lighten(color(primary), 10%);
  }

  &:focus-visible {
    background-color: lighten(color(primary), 20%);
  }
}


/**
 * Flat button
 */
.btn-flat {
  background-color: transparent;
  color: #202020;
  box-shadow: none;
  @extend %button-defaults;

  &:hover {
    background-color: rgba(#000, 10%);
    color: #202020;
  }

  &:active {
    background-color: rgba(#000, 20%);
    color: #202020;
  }

  &:focus-visible {
      background-color: rgba(#000, 20%);
  }
}



// Add more button styles below...