.theme_header {
    background-color: map-get($map: $colors, $key: blue-300);
    background-image: url("/img/bg-header.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    .header_content {
        h1 {
            font-size: 1.6rem;
            font-weight: 400;
            color: map-get($map: $colors, $key: white);
        }
        h2 {
            font-size: 3.2rem;
            font-weight: 600;
            color: map-get($map: $colors, $key: white);
        }
    }

    .img-wrapper {
        width: 120px;
        padding: 1rem;
        aspect-ratio: 1;
        border-radius: 50%;
        background-color: map-get($map: $colors, $key: white);
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 0px 12px 2px rgba(0, 0, 0, 0.05);
    }
}
