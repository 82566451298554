/**
 * General reset
 */
html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    scroll-behavior: smooth;

    /**
   * Custom scrollbar
   */
    &::-webkit-scrollbar {
        width: 1.6rem;
    }

    &::-webkit-scrollbar-track {
        background-color: #fff;
    }

    &::-webkit-scrollbar-thumb {
        background-color: color(primary);
        outline: none;
        border-radius: 0rem;
    }

    /**
   * Custom selection color
   */
    ::selection {
        background-color: color(secondary);
        color: #fff;
    }
}

html,
body {
    font-size: 62.5%;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

body {
    font-size: 1.6rem;

    main {
        flex: 1;
    }
}

/**
 * Bootstrap reset
 */

// Tooltips
.tooltip {
    font-size: 16px;

    .tooltip-arrow {
        &::before {
            border-right-color: rgba(#3a3a3a, 90%);
        }
    }

    .tooltip-inner {
        padding: 8px 16px;
        background-color: rgba(#3a3a3a, 90%);
        border-radius: 4px;
    }
}

img {
    -webkit-user-drag: none;

    &.px {
        image-rendering: pixelated;
    }

    &.aa {
        image-rendering: -webkit-optimize-contrast;
        image-rendering: -moz-crisp-edges;
        image-rendering: -o-crisp-edges;
        image-rendering: crisp-edges;
        image-rendering: optimize-contrast;
        -ms-interpolation-mode: nearest-neighbor;
    }
}

a {
    text-decoration: none;
    color: color(primary);
}

ul {
    list-style: none;
}

/* Animations */
@keyframes floating {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(15px);
    }

    100% {
        transform: translateY(0);
    }
}

/* Helpers */
.up {
    text-transform: uppercase;
}

.greyscale {
    filter: grayscale(100%);
}

[disabled]:hover {
    cursor: not-allowed;
}

/* Buttons */
button {
    all: unset;
}

// Bootstrap resets and fix
.btn {
    padding: 12px 16px;
    font-size: 16px;
}

.form-control:focus {
    box-shadow: none;
}
