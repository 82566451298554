@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");

* {
    font-family: "Montserrat", sans-serif;
}

h1 {
    font-size: calc(1vw + 1.5rem);
}
h2 {
    font-size: calc(1vw + 1.3rem);
}

h3 {
    font-size: calc(1vw + 1.2rem);
}

p {
    font-size: calc(1vw + 0.2rem);
}

@media (max-width: 1399.98px) {
    p {
        font-size: calc(1vw + 0.4rem);
    }
}

@media (max-width: 1199.98px) {
    p {
        font-size: calc(1vw + 0.6rem);
    }
}

@media (max-width: 991.98px) {
    p {
        font-size: calc(1vw + 0.8rem);
    }
}

@media (max-width: 767.98px) {
    p {
        font-size: calc(1vw + 0.9rem);
    }
}

@media (max-width: 575.98px) {
    p {
        font-size: calc(1vw + 1rem);
    }
    h1,
    h2,
    h3,
    h4,
    p {
        text-align: center;
    }
}
