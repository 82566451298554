.layer-bredi {
    background-color: map-get($map: $colors, $key: blue-500);
    a,
    span {
        color: white;
        font-size: 1.2rem;
    }

    .wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1rem 0;

        small {
            color: white;
            font-size: 1.4rem;
        }

        .fab {
            font-size: 22px;
        }
    }
}
