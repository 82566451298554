.theme_menu {
    .nav-link {
        background-color: map-get($map: $colors, $key: blue-300);
        color: map-get($map: $colors, $key: white);
        font-size: 1.4rem;
        padding: 1rem 3rem;
        max-width: 220px;
        &.active {
            background-color: map-get($map: $colors, $key: blue-500);
        }
    }

    .form-floating {
        label {
            font-size: 1.6rem;
        }

        input,
        textarea {
            padding-top: 14px;
            font-size: 1.6rem;
        }

        .form-control {
            height: auto;
            &:focus {
                border-color: map-get($map: $colors, $key: blue-500);
            }
        }
        .form-floating > .form-control:focus,
        .form-floating > .form-control:not(:placeholder-shown) {
            padding-top: 2rem;
        }
    }

    .tab-content {
        width: 50%;
        @media (max-width: 575.98px) {
            width: 100%;
        }
    }

    .form_title {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 900;
        font-size: 16px;
        line-height: 150%;
        color: map-get($map: $colors, $key: zinc-500);
        &.head_title {
            font-size: 22px;
            background: map-get($map: $colors, $key: blue-500);
            color: #fff;
            padding: 4px 8px;
            border-radius: 0.25rem;
        }
    }

    .form-check-input:checked {
        background-color: map-get($map: $colors, $key: blue-500);
        border-color: map-get($map: $colors, $key: zinc-200);
    }
}

.form-floating > .form-control:focus,
.form-floating > .form-control:not(:placeholder-shown) {
    padding-top: 2.625rem;
}

.tab_login_registro {
    max-width: 450px;
    margin: 0 auto;
    .nav-item {
        width: 50%;
        .nav-link {
            width: 100%;
            text-align: center;
            color: #c2c2c2;
            font-weight: 600;
            border-bottom: 2px solid #c2c2c2;
            &.active {
                background-color: transparent;
                color: map-get($map: $colors, $key: blue-300);
                border-bottom: 2px solid map-get($map: $colors, $key: blue-300);
            }
        }
    }
}
