.theme_welcome {
    display: grid;
    place-items: center;
    .theme_welcome_content {
        max-height: 600px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 220px;
        p {
            text-align: center;
            font-weight: 600;
            color: map-get($map: $colors, $key: blue-500);
        }
        a {
            background-color: map-get($map: $colors, $key: blue-300);
            color: map-get($map: $colors, $key: white);
            font-size: 1.6rem;
            padding: 1rem 3rem;
            transition: all 0.2s ease;
            &:hover {
                background-color: map-get($map: $colors, $key: blue-500);
            }
        }
    }
}
