@import "global/variables";
@import "global/mixins";
@import "global/functions";
@import "global/reset";
@import "global/fonts";
@import "global/colors";

@import "components/components";

@import "pages/welcome";
@import "pages/main-form";

body {
    background-image: url("/img/bg-main.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.debug {
    border: 1px solid red;
}

.form-check-input:checked {
    background-color: #13a14e;
    border-color: #cfcfcf;
}

.btn-primary {
    background-color: #13a14e;
    border-color: #cfcfcf;
    &:hover {
        background-color: #50966d;
        border-color: #cfcfcf;
    }
}

// Mobile-first
#layout {
    // background-image: url("/img/fundo-repeatable.png");
    background-repeat: repeat;
}

#form-theme {
    .form-check-input {
        width: 2rem;
        height: 2rem;
        margin-right: 10px;
    }
    .table-container {
        overflow: auto;
        &::-webkit-scrollbar {
            width: 1rem;
            height: 1rem;
        }

        &::-webkit-scrollbar-track {
            background-color: #fff;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #165fad;
            outline: none;
            border-radius: 0rem;
        }
    }

    .escolhas {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    form {
        margin-top: 3rem;
        padding: 2rem;
        border-radius: 0.5rem;
    }
    h1 {
        font-family: "Anton", sans-serif;
        font-weight: 900;
        color: rgb(27, 136, 0);

        font-size: 8rem;
        @media (max-width: 575.98px) {
            font-size: 3rem;
        }
    }

    h2,
    h3,
    h4,
    h5,
    h6 {
        font-weight: 700;
        color: rgb(27, 136, 0);
    }
    p,
    th,
    td,
    label {
        color: rgb(27, 136, 0);
    }

    h2 {
        font-size: 2.5rem;
    }

    th {
        min-width: 12rem;
        text-align: center;
    }

    th,
    td {
        @media (max-width: 575.98px) {
            font-size: 13px;
        }
        text-align: center;
    }
}

body {
    &::-webkit-scrollbar {
        width: 1rem;
        height: 1rem;
    }

    &::-webkit-scrollbar-track {
        background-color: #fff;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #165fad;
        outline: none;
        border-radius: 0rem;
    }
}

.button-save {
    position: fixed;
    right: 20px;
    bottom: 40px;
    button {
        background-color: #165fad;
        color: #fff;
        width: 120px;
        &:hover {
            background-color: #123e6d;
            color: #fff;
        }
    }
}

.tab_login_registro {
    .form-check-input:checked {
        background-color: #165fad;
        border-color: #cfcfcf;
    }

    input:not([type="radio"]) {
        outline: none;
        border: 1px solid #c2c2c2;
        padding: 1rem 2rem;
        width: 100%;
        &:focus {
            border: 1px solid #165fad;
        }
    }
    .btn_primary {
        background-color: #165fad;
        color: #fff;
    }
    a {
        color: #165fad;
        font-weight: 600;
        &:hover {
            color: #123e6d;
        }
    }
}

.footer_login_registro {
    background-color: #fff;
    border-top: 1px solid #c2c2c2;
    a,
    span {
        color: #c2c2c2;
    }
}

/*the container must be positioned relative:*/
.autocomplete {
    position: relative;

    .autocomplete-items {
        position: absolute;
        border: 1px solid #d4d4d4;
        border-bottom: none;
        border-top: none;
        z-index: 99;
        /*position the autocomplete items to be the same width as the container:*/
        top: 100%;
        left: 0;
        right: 0;
    }

    .autocomplete-items div {
        padding: 10px;
        cursor: pointer;
        background-color: #fff;
        border-bottom: 1px solid #d4d4d4;
    }

    /*when hovering an item:*/
    .autocomplete-items div:hover {
        background-color: #e9e9e9;
    }

    /*when navigating through the items using the arrow keys:*/
    .autocomplete-active {
        background-color: DodgerBlue !important;
        color: #ffffff;
    }
}

.bg_title {
    background-image: url("/img/bg-title.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    padding: 1rem;
    margin-block: 4rem;
    h1 {
        color: #ffffff;
        font-size: 2rem;
        @media (max-width: 575.98px) {
            font-size: 1.5rem;
        }
    }
}
