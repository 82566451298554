.progress-bar-component {
    max-width: 960px;
    margin: 0 auto;
    padding: 2rem 0;
    font-family: "Exo", sans-serif;
    color: #4b4b4b;
    counter-reset: step-count;
    .progress-bar {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(20px, 1fr));
        background-color: transparent !important;
    }

    .progress-step {
        text-align: center;
        position: relative;

        &:before,
        &:after {
            background-color: #13a14e;
            content: "";
            height: 2px;
            position: absolute;
            z-index: -1;
            top: 15px;
        }

        &:after {
            left: 50%;
            width: 100%;
        }

        &:last-of-type.is-active:after {
            background-color: #13a14e;
        }

        @media screen and (min-width: 640px) {
            &:first-of-type:before {
                right: 50%;
                width: 50%;
            }
        }

        @media screen and (max-width: 640px) {
            &:first-of-type:before,
            &:last-of-type:after {
                background-color: white !important;
            }
        }

        &:last-of-type:after {
            left: 50%;
            width: 50%;
        }

        .step-count {
            background-color: #13a14e;
            height: 30px;
            width: 30px;
            margin: 0 auto;
            border-radius: 50%;
            color: white;
            line-height: 30px;
            z-index: 100;
            border: 7px solid rgb(20, 20, 20);
            font-size: 1.2rem;
            font-weight: 500;

            &:before {
                counter-increment: step-count;
                content: counter(step-count);
                position: absolute;
                top: 0px;
                left: 49%;
                font-weight: 600;
            }
        }

        &.is-active {
            .step-description {
                font-weight: 500;
            }
            &:after {
                background-color: #252525;
            }

            ~ .progress-step .step-count {
                background-color: #252525;
            }

            ~ .progress-step:after {
                background-color: #252525;
            }
        }
    }

    .step-description {
        font-size: 1rem;
    }
}
